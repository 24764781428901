import m, {Component} from 'mithril'
import {Picture} from '../picture'

type HomepageVideoItemAttrs = {
    idx: number
    alt: string
    title: string
    subtitle: string
    onclick: () => void
}

export const HomepageVideoItem:Component<HomepageVideoItemAttrs> = {
    view: vnode =>
        m('.hp-gallery-item',
            m(Picture, {
                srcset: [{src:`images/homepage/homepage.slideshow.${vnode.attrs.idx}.jpg`, size: '1x'}],
                srcsetMobile: [{src:`images/homepage/homepage.slideshow.mobile.portrait.${vnode.attrs.idx}.jpg`, size: '1x'}],
                alt: vnode.attrs.alt,
                onload: m.redraw
            }),
            m('.hp-gallery-captions',
                m('.caption-title', vnode.attrs.title),
                m('.caption-subtitle', vnode.attrs.subtitle),
                m('button.sp-button', {onclick: vnode.attrs.onclick},
                    'WATCH',
                    m('div', {class: 'type-arrow type-arrow-right'})
                )
            )
        )
}