import m, {ChildArray, ClosureComponent} from 'mithril'
import classnames from 'classnames'
import {Picture} from '../picture'
import {shift, slide} from '../../slide'
import {repeat} from '../../array'
import {onResize} from '../../resize'

const LOOP_REPS = 3

type State = {
    slideWidth: number
    trackWidth: number
    position: number
    distance: number
}

type Attrs = {
    loop?: 'rewind' | 'carousel'
    overlay: boolean
    distance: number
    nav: 'light' | 'solid' | 'mid' | 'simple'
    resize?: boolean,
    type?: 'main' | 'details' | 'finishes',
    navtop?: () => number
}

const getDiv = (anc: Element, selector: string) =>
    anc.querySelector(selector) as HTMLDivElement | undefined

export const Slider:ClosureComponent<Attrs> = vnode => {
    const state: State = {
        slideWidth: 0,
        trackWidth: 0,
        position: 0,
        distance: Math.min(vnode.attrs.distance, 1)
    }
    const slider = () => vnode['dom'] as Element
    const overflow = () => state.slideWidth > state.trackWidth
    const calculate = () => {
        const track = getDiv(slider(), '.track')
        if (!track)
            return false
        const slideWidth = getDiv(track, '.slide')!.clientWidth
        const next = {
            slideWidth: (vnode.attrs.loop === 'carousel' && overflow()) ? slideWidth / LOOP_REPS : slideWidth,
            trackWidth: track.clientWidth
        }
        if (state.slideWidth === next.slideWidth && state.trackWidth === next.trackWidth)
            return false
        return !!Object.assign(state, next)
    }
    const onShift = async (dir: 'left' | 'right') => {
        const pos = shift(state.trackWidth, state.slideWidth, state.position, dir)
        const slide = getDiv(slider(), '.slide')
        if (!slide || pos === state.position)
            return
        state.position = pos
        slide.style.transition = 'none'
        slide.style.transform = `translateX(${state.position}px)`
        // Wait for the dom to update before changing css again
        await new Promise(res => setTimeout(res, 1000/60))
        slide.style.transition = 'transform .5s ease'
    }
    const onSlide = async (dir: 'left' | 'right') => {
        if (vnode.attrs.loop === 'carousel')
            await onShift(dir)
        const pos = slide(state.trackWidth, state.slideWidth, state.position, state.distance, dir, vnode.attrs.loop)
        if (pos === state.position)
            return
        state.position = pos
        m.redraw()
    }
    return {
        oninit: vnode => vnode.attrs.resize && onResize(() => {
            const r = state.position / state.slideWidth
            calculate()
            state.position = r * state.slideWidth
        }),
        oncreate: () => slider().classList.add('initialized'),
        onupdate: () => calculate() && m.redraw(),
        view: vnode => {
            const loop = !!vnode.attrs.loop && overflow()
            const carousel = vnode.attrs.loop === 'carousel' && overflow()
            const maxLeft = !loop && state.position === 0
            const maxRight = !loop && state.position === state.trackWidth - state.slideWidth
            const navsrcset = (dir: 'left' | 'right', retina: boolean) => {
                const set = [{src:`images/moma/nav-${vnode.attrs.nav}-${dir}.png`, size: '1x'}]
                return retina ? [...set, {src:`images/moma/nav-${vnode.attrs.nav}-${dir}@2x.png`, size: '2x'}] : set
            }
            const navstyle = vnode.attrs.navtop && {top: `${vnode.attrs.navtop()}px`}
            const children = () =>
                carousel
                    ? repeat(vnode.children as ChildArray, LOOP_REPS)
                        .map((child: m.Vnode, key) => ({...child, key}))
                    : vnode.children
            return m('.slider',
                m('div', {class: classnames('nav', 'left', {
                        hidden: !overflow(),
                        disabled: maxLeft,
                        overlay: vnode.attrs.overlay,
                    }),
                    style: navstyle
                    },
                    m(Picture, {
                        srcset: navsrcset('left', vnode.attrs.nav !== 'simple'),
                        alt: `nav-${vnode.attrs.nav}-left`,
                        width:'22',
                        height: '52',
                        onclick: () => onSlide('left')
                    })
                ),
                m('.track', {class: classnames({overflow: overflow()})},
                    m('.slide', {style:{
                            transition: 'transform .5s ease',
                            transform: `translateX(${state.position}px)`,
                            marginLeft: carousel && `-${state.slideWidth}px`
                        }},
                        children()
                    )
                ),
                m('div', {class: classnames('nav', 'right', {
                        hidden: !overflow(),
                        disabled: maxRight,
                        overlay: vnode.attrs.overlay,
                    }),
                    style: navstyle
                    },
                    m(Picture, {
                        srcset: navsrcset('right', vnode.attrs.nav !== 'simple'),
                        alt: `nav-${vnode.attrs.nav}-right`,
                        width:'22',
                        height: '52',
                        onclick: () => onSlide('right')
                    })
                )
            )
        }
    }
}