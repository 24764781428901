import m, {Component} from 'mithril'
const {hashfile} = require('../hashfile')

export type Src = {src: string, size: string}

type Attrs = {
    srcset: Src[],
    alt: string,
    srcsetMobile?: Src[],
    width?: string,
    height?: string,
    loading?: 'lazy' | 'eager'
    onclick?: () => void
    onload?: (ev: Event) => void
}

type WrapAttrs = {
    onclick?: () => void
    loading?: 'lazy' | 'eager'
}

const hashSrc = (set: Src[]) => {
    const webp:Src[] = []
    const jpeg:Src[] = []
    set.forEach(src => {
        const last = src.src.lastIndexOf('/')
        const path = src.src.slice(0, last)
        const name = src.src.slice(last + 1)
        webp.push({src: hashfile(path, name+'.webp'), size: src.size})
        jpeg.push({src: hashfile(path, name), size: src.size})
    })
    return [webp, jpeg]
}
const mimeType = (filename) => {
    const type = filename.substring(filename.lastIndexOf(".") + 1)
    return `image/${type !== 'jpg' ? type : 'jpeg'}`
}

const PictureWrapper:Component<WrapAttrs> = {
    view: vnode => {
        return m('picture', {onclick: vnode.attrs.onclick}, [
            vnode.attrs.loading === 'lazy' ?
                m('noscript.loading-lazy', vnode.children)
                : vnode.children
        ])
    }
}

export const Picture:Component<Attrs> = {
    view: vnode => {
        const isMobile = !!vnode.attrs.srcsetMobile
        const [webp, jpeg] = hashSrc(vnode.attrs.srcset)
        const jsrcset = jpeg.map(src => `${src.src} ${src.size}`).join(', ')
        const wsrcset = webp.map(src => `${src.src} ${src.size}`).join(', ')
        const type = mimeType(vnode.attrs.srcset[0].src)
        const mobileSrcSet:{webp?:string, jpeg?:string} = {}
        
        if(vnode.attrs.srcsetMobile) {
            mobileSrcSet.webp = hashSrc(vnode.attrs.srcsetMobile!)[0].map(src => 
                `${src.src} ${src.size}`).join(', ')
            mobileSrcSet.jpeg = hashSrc(vnode.attrs.srcsetMobile!)[1].map(src => 
                `${src.src} ${src.size}`).join(', ')
        }
        
        return m(PictureWrapper, {onclick: vnode.attrs.onclick, loading: vnode.attrs.loading},
                m('source', {srcset: wsrcset, type: 'image/webp', media: isMobile ? '(min-width: 851px)' : undefined}),
                m('source', {srcset: jsrcset, type, media: isMobile ? '(min-width: 851px)' : undefined }),
                isMobile
                    ? [
                        m('source', {srcset: mobileSrcSet.webp, type: 'image/webp', media: '(max-width: 850px)'}),
                        m('source', {srcset: mobileSrcSet.jpeg, type, media: '(max-width: 850px)'}),
                    ]
                    : null,
                m('img', {
                    src: jpeg[0].src,
                    alt: vnode.attrs.alt,
                    width: vnode.attrs.width,
                    height: vnode.attrs.height,
                    onload: vnode.attrs.onload,
                    loading: vnode.attrs.loading
                })
        )
    }
}