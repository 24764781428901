import m from 'mithril'
import {Slider} from './slider'
import {Modal} from '../modal'
import {HomepageVideoItem} from './homepage-video-item'
import {Video} from '../../types/video'

const state: {video?: Video} = {
    video: undefined
}

if (window.homepageVideoNode) {
    const node = window.homepageVideoNode
    const items = JSON.parse(node.textContent || '[]') as Video[]
    const navtop = () => (node.querySelector('.slide img') || {clientHeight: 0}).clientHeight / 2
    const mountSlider = () => {
        m.mount(node, {view: () =>
            m(Slider, {
                resize: true,
                loop: 'carousel',
                overlay: true,
                nav: 'solid',
                distance: 1,
                navtop
                },
                items.map((item, idx) =>
                    m(HomepageVideoItem, {
                        idx,
                        ...item,
                        onclick: () => {state.video = item}
                    })
                )
            )
        })
        node.classList.remove('hidden')
    }
    window.addEventListener('scroll', mountSlider, {once: true})
}

if (window.homepageVideoModalNode) 
    m.mount(
        window.homepageVideoModalNode, {view: () =>
            m(Modal, {open: !!state.video, close: () => state.video = undefined},
                m('h2', state.video && state.video.videoTitle),
                m('iframe', {
                    id: state.video && state.video.VimeoID,
                    src: state.video && `//player.vimeo.com/video/${state.video.VimeoID}?autoplay=1&api=1&player_id=${state.video.VimeoID}`,
                    frameBorder: 0,
                    allowFullScreen: true,
                    title: state.video && state.video.videoTitle
                })
            )
    })