import m from 'mithril'
import {Slider} from './slider'
import {LinkItem} from './link-item'
import Detect from '../../detect'
import {Item} from '../../types/item'
import {crossFade} from "../../crossfade"
import {setBreadcrumbs} from '../collections-nav'
import {tabItem} from '../product-tabs'
import { MEDIA, SWIPE_THRESHOLD } from '../../constants'
import {copy} from '../../object'
import {onView} from '../../recently-viewed'
import {onBreak} from '../../resize'

type State = {
    items: Item[]
    item?: Item
    x?: null | number
    y?: null | number
    currentItemIndex?: number | null
    scrollY?: number
    swiping?: 'x' | 'y'
    nextIdx?: number
    posTop?: number
}

const state: State = {
    items: [],
    item: undefined,
    x: null,
    y: null,
    currentItemIndex: null,
    scrollY: undefined,
    swiping: undefined,
    posTop: undefined
}

type DefaultVisit = {
    remember: boolean,
    lightbox: boolean,
    swipe: boolean,
    subId?: string

}
const defaultVisit:DefaultVisit = {
    remember:true,
    lightbox:false,
    swipe:false,
    subId: undefined
}

export const currentItem = () => state.item

const page = (
    item: Item,
    lightbox:boolean = false,
    subId: string | undefined = undefined
    ): [any, string, string] => {
        return [
            {itemId: item.id},
            '',
            `${item.url}${lightbox ? '#' + (subId || item.imageID)  : ''}`
        ]
    }

const updatePinterest = (item: Item) => {
    window.itemPin!.href = `https://www.pinterest.com/pin/create/button/?url=${item.pinterest!.url}&media=${item.pinterest!.media}&description=${item.collection}`
}

const updateMainLightbox = (item: Item) => {
    window.mainLightbox!.div.setAttribute('id',`${item.imageID}`)
    window.mainLightbox!.img.setAttribute('src',`${MEDIA}/${item.filename}`)
    window.mainLightbox!.img.setAttribute('height',`${item.height}`)
    window.mainLightbox!.img.setAttribute('width',`${item.width}`)
    window.mainLightbox!.img.setAttribute('alt',`${item.alt}`)
    window.mainLightbox!.img.setAttribute('loading','lazy')
    window.itemZoom!.setAttribute('href',`#${item.imageID}`)
}

const updateCurrentItem = (item: Item) => {
    state.item = item
    state.currentItemIndex = state.items.findIndex(i => i.id === item.id)
    window.currentItem = {
        id: item.id,
        groupID: Number(item.groupID),
        height: Number(item.height),
        width: Number(item.width),
        imageID: Number(item.imageID),
        metaID: Number(item.metaID),
        aspect: Number(item.aspect),
        filename:item.filename,
        alt: item.alt,
        url: item.url,
        cat_url: item.cat_url,
        collection: item.collection
    }
}

export const visit = (item: Item, options: Partial<typeof defaultVisit> = {}) => {
    const opts = Object.assign(copy(defaultVisit), options)
    updateCurrentItem(item)
    if (opts.remember)
        history.pushState(...page(item, opts.lightbox, opts.subId))
    onView(item)
    setBreadcrumbs(item.breadcrumbs!)
    tabItem(item)
    crossFade(item)
    updatePinterest(item)
    updateMainLightbox(item)
    if (Detect.mobile() && !opts.swipe)
        window.scrollTo({top: 0, left: 0, behavior:'smooth'})
    m.redraw()
}

const subVisit = (dir: 'left' | 'right', itemIndex, collection) => {
    const nextIndex = dir === 'left'
                        ? itemIndex - 1 >= 0
                            ? itemIndex - 1
                            : collection.length - 1
                        : itemIndex + 1 > collection.length - 1
                            ? 0
                            : itemIndex + 1
    window.location.href= '#' + collection[nextIndex].imageID
}

const nextIdx = (dir: 'left' | 'right', currIndex = state.currentItemIndex!, items = state.items) =>
    (currIndex + items.length + (dir === 'left' ? -1 : 1)) % items.length

const touch = (e:TouchEvent) => {
    state.scrollY = window.scrollY
    state.x = e.changedTouches[0].clientX
    state.y = e.changedTouches[0].clientY
    state.posTop = document.documentElement.scrollTop
}

const swipe = (e:TouchEvent) => {
    e.preventDefault()
    const {clientX, clientY} = e.changedTouches[0]
    const dy = clientY - state.y!
    if (!state.swiping)
        state.swiping = Math.abs(state.y! - clientY) > 5
            ? 'y'
            : Math.abs(state.x! - clientX) > 10 ? 'x' : undefined
    else if (state.swiping === 'y')
        document.documentElement.scrollTop = state.posTop! - dy
    else if (Math.abs(state.x! - clientX) > SWIPE_THRESHOLD)
        state.nextIdx = nextIdx(clientX < state.x! ? 'left' : 'right')
}
// window.scrollTo(window.scrollX, document.body.scrollTop + (window.scrollY - clientY));
const release = (e:TouchEvent) => {
    if (state.nextIdx !== undefined)
        visit(state.items[state.nextIdx], {swipe: true})
    state.swiping = undefined
    state.nextIdx = undefined
}

const handleTouch = () => {
    if (Detect.mobile() && window.mainLightbox && window.mainImageAnchor) {
        state.currentItemIndex = state.items.findIndex(i => i.id === state.item!.id)
        window.mainLightbox.img.addEventListener('touchstart', touch),false
        window.mainLightbox.img.addEventListener('touchmove', (e) => e.preventDefault(),false)
        window.mainLightbox.img.addEventListener('touchend', (e) => {
            const {clientX} = e.changedTouches[0]
            if(Math.abs(state.x! - clientX) > 60)
                visit(state.items[nextIdx(clientX < state.x! ? 'left' : 'right')], {lightbox: true, swipe: true})
        },false)
    }
}

const handleTouchSubImage = (element, collection, currentIndex) => {
    if (Detect.mobile() && element &&  window.itemData) {
        element.div.addEventListener('touchstart', touch),false
        element.div.addEventListener('touchmove', (e) => e.preventDefault(),false)
        element.div.addEventListener('touchend', (e) => {
            const {clientX} = e.changedTouches[0]
            if(Math.abs(state.x! - clientX) > 60)
                subVisit(clientX < state.x! ? 'right' : 'left', currentIndex, collection)

        },false)
    }
}

if (window.seriesNode) {
    window.addEventListener('popstate', event => {
        if (!event.state)
            return
        visit(state.items.find(item => item.id === event.state.itemId)!, {remember: false})
        if (event.state.lightbox)
            window.location.href = event.state.lightbox
    })
    state.items = JSON.parse(window.seriesNode.textContent || '[]')
    state.item = state.items.find(item => item.id === window.currentItem!.id)
    m.mount(window.seriesNode, {view: () =>
        m(Slider, {
            loop: 'rewind',
            overlay: false,
            nav: Detect.mobile() ? 'simple' : 'mid',
            distance: 0.8,
            },
            state.items.map(item =>
                m(LinkItem, {
                    ...item,
                    name: undefined,
                    onclick: () => visit(item)
                })
            )
        )
    })
    const hash = window.location.hash || undefined
    const target = hash && document.getElementById(hash.substring(1))
    const lightbox = target ? target.classList.contains('lightbox') : false
    history.replaceState(...page(state.item!, lightbox))
    onView(state.item!)
    onBreak(handleTouch)
    handleTouch()
    if(window.subLightboxes){
        setTimeout(() =>{
            for(const lightbox of window.subLightboxes!){
                const collection = window.itemData![lightbox.type]
                const currentIndex = collection.findIndex(f => String(f.imageID) === lightbox.div.getAttribute('id'))
                handleTouchSubImage(lightbox,collection, currentIndex)
            }
        },0)
    }
    if(window.lightBoxArrows){
        setTimeout(() =>{
            for(const nav of window.lightBoxArrows!) {
                nav.left.addEventListener('click', (e) => {
                    window.openedLightbox = nav.type as 'main' | 'finishes' | 'details'
                    if(nav.type === 'main'){
                        visit(state.items[nextIdx('left')], {lightbox: true, swipe: true})

                    }else {
                        const collection = window.itemData![nav.type]
                        const itemIndex = collection.findIndex(f => String(f.imageID) === nav.id)
                        subVisit('left', itemIndex, collection)
                    }
                })
                nav.right.addEventListener('click', (e) => {
                    window.openedLightbox = nav.type as 'main' | 'finishes' | 'details'
                    if(nav.type === 'main'){
                        visit(state.items[nextIdx('right')], {lightbox: true, swipe: true})
                    }else {
                        const collection = window.itemData![nav.type]
                        if(collection){
                            const itemIndex = collection.findIndex(f => String(f.imageID) === nav.id)
                            subVisit('right', itemIndex, collection)
                        }
                    }
                })
            }
        }
        , 0)

    }
    const handleArrowKeys = e => {
        const type = window.openedLightbox
        if((e.key === 'ArrowLeft' || e.keyCode === 37) && type){
            const lightbox = window.location.href.split('#')[1]
            if(type === 'main' && lightbox){
                visit(state.items[nextIdx('left')], {lightbox: true, swipe: true})
            }else{
                const collection = window.itemData![type]
                if(collection){
                    const itemIndex = collection.findIndex(f => String(f.imageID) === lightbox)
                    if(itemIndex !== -1)
                        subVisit('left', itemIndex, collection)
                }
            }
        }
        if((e.key === 'ArrowRight' || e.keyCode === 39) && type){
            const lightbox = window.location.href.split('#')[1]
            if(type === 'main' && lightbox){
                visit(state.items[nextIdx('right')], {lightbox: true, swipe: true})
            }else{
                const collection = window.itemData![type]
                if(collection){
                    const itemIndex = collection.findIndex(f => String(f.imageID) === lightbox)
                    if(itemIndex !== -1)
                        subVisit('right', itemIndex, collection)
                }
            }
        }
    }
    window.addEventListener('keydown', handleArrowKeys)
}

