import m, {Component} from 'mithril'
import {Item} from '../types/item'
import {Slider} from './slider/slider'
import {DynamicGrid} from './dynamic-grid'
import Detect from '../detect'
import {onBreak} from '../resize'
import { LinkItem } from './slider/link-item'

const GRID_WIDTH = 490

type Desc = 'story' | 'size' | 'details' | 'finish' | 'splash'

type State = {
    item?: Item
    active: Desc[]
    details: any[]
    finishes: any[]
    isMobile: boolean
}

const state:State = {
    item: undefined,
    active: [],
    details: [],
    finishes: [],
    isMobile: false
}

const oncheck = (e: any, tab: Desc) => {
    state.active = []
    if (!Detect.mobile() || e.target.checked)
        state.active.push(tab)
}

const desc = (key: Desc) => state.item!.descriptions![key] || ''

const count = () =>
    [!!desc('story'),
    !!desc('size'),
    !!desc('details') || !!state.details.length,
    !!desc('finish') || !!state.finishes.length]
        .reduce((count, tab) => tab ? count + 1 : count, 0)

const reset = () => {
    state.isMobile = Detect.mobile()
    state.active =
        !state.isMobile
            ? desc('story')
                ? ['story']
                : desc('size')
                    ? ['size']
                    : desc('finish') || state.finishes.length
                        ? ['finish']
                        : desc('details') || state.details.length
                            ? ['details']
                            : []
            : []
}

export const tabItem = (item: Item) => {
    state.item = item
    reset()
}

const init = (data: any) => {
    state.item = data.item
    state.details = data.details
    state.finishes = data.finishes
    reset()
}

const ProductTabs:Component = {
    view: vnode => [
        desc('story') && [
            m('input.tab-nav-item#product-story',{
                type: 'checkbox',
                name: 'tabs',
                checked: state.active.includes('story'),
                onchange: e => oncheck(e,'story')
            }),
            m('label.tab-label.tab-label-story', {
                for: 'product-story'
            }, 'Story'),
                m('.tab.product-story-content',
                    m.trust(desc('splash')),
                    m.trust(desc('story'))
                )
        ],
        desc('size') && [
            m('input.tab-nav-item#product-dimensions',{
                type: 'checkbox',
                name: 'tabs',
                checked: state.active.includes('size'),
                onchange: e => oncheck(e,'size')
            }),
            m('label.tab-label.tab-label-dimensions', {
                for: 'product-dimensions'
            }, 'Dimensions'),
                m('.tab.product-dimensions-content',
                    m.trust(desc('size'))
                )
        ],
        (!!state.details.length || desc('details')) && [
            m('input.tab-nav-item#product-details',{
                type:'checkbox',
                name:'tabs',
                checked: state.active.includes('details'),
                onchange: e => oncheck(e,'details')
            }),
            m('label.tab-label.tab-label-details', {
                for: 'product-details'
            }, 'Details'),
                m('.tab.product-details-content',
                    m.trust(desc('details')),
                    !!state.details.length && [
                        Detect.mobile()
                            ? m(Slider, {
                                loop: 'rewind',
                                overlay: false,
                                nav: 'simple',
                                distance: 0.8,

                                },
                                state.details.map(item =>
                                    m(LinkItem, {
                                        ...item,
                                        type: 'details',
                                        aspect: item.width / item.height,
                                        height: Math.floor(0.15 * window.innerHeight)
                                    })
                                )
                            )
                            : m(DynamicGrid, {
                                params: {
                                    thumbs: state.details,
                                    width: GRID_WIDTH,
                                    targetHeight: 100,
                                    gap: 6
                                },
                                type:'details'
                            })
                    ]
                )
        ],
        (!!state.finishes.length || desc('finish')) && [
            m('input.tab-nav-item#product-finishes',{
                type:'checkbox',
                name:'tabs',
                checked: state.active.includes('finish'),
                onchange: e => oncheck(e,'finish')
            }),
            m('label.tab-label.tab-label-finishes', {
                for: 'product-finishes'
            }, 'Finishes'),
                m('.tab.product-finishes-content',
                m.trust(desc('finish')),
                !!state.finishes.length && [
                    Detect.mobile()
                        ? m(Slider, {
                            loop: 'rewind',
                            overlay: false,
                            nav: 'simple',
                            distance: 0.8,
                            },
                            state.finishes.map(item =>
                                m(LinkItem, {
                                    ...item,
                                    type: 'finishes',
                                    aspect: item.width / item.height,
                                    height: Math.floor(0.15 * window.innerHeight)
                                })
                            )
                        )
                        : m(DynamicGrid, {
                            params: {
                                thumbs: state.finishes,
                                width: GRID_WIDTH,
                                targetHeight: 100,
                                gap: 6
                            },
                            type:'finishes'
                        })
                ]
                )
        ],
        m('.scrollhint-top'),
        m('.scrollhint-bottom'),
    ]
}

if (window.productTabsNode) {
    const node = window.productTabsNode
    const data = JSON.parse(node.getAttribute('tabData') || '{}')
    init(data)
    node.classList.add(`tabs-${count()}`)
    m.mount(node, ProductTabs)
    onBreak(reset)
}