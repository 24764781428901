import m from 'mithril'
import {Slider} from './slider'
import {LinkItem} from './link-item'
import {Item} from '../../types/item'
import {currentItem} from './series'
import {recent} from '../../recently-viewed'
import {onBreak} from '../../resize'
import Detect from '../../detect'

const node = window.recentlyViewedNode

const mount = () => {
    const getItems = () => recent().filter(i => {
        const current = currentItem()
        return !current || i.id !== current.id
    }) as Item[]
    m.mount(node!, {view: () => {
        const items = getItems()
        node!.closest('.recent')!.classList[items.length ? 'remove' : 'add']('hidden')
        return m(Slider, {
            overlay: false,
            nav: 'light',
            distance: 0.8,
            },
            items.map(item =>
                m(LinkItem, {
                    ...item,
                    name: undefined
                })
            )
        )
    }})
}

if (node)
    !Detect.mobile()
        ? mount()
        : onBreak(mount, true)
