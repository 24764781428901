import m, {ClosureComponent} from 'mithril'
import { Picture } from './picture'
const DynamicLayout = require('../dynamic-layout/dynamic-layout.js')
import LinkTile from './linktile'
import {Item} from '../types/item'
import {equal} from '../object'

type Thumb = Item & {
    resize: {width: number, height: number}
}

type Row = Thumb[]

type Params = {
    thumbs: Item[]
    width: number
    targetHeight: number
    gap: number
}

type Attrs = {
    params: Params
    arrows?: boolean
    showTitles?: boolean
    scale?: boolean
    type?: 'main' | 'finishes' | 'details'
}

type State = {
    rows: Row[]
    count: number
    page: number
    redistribute: boolean
}

const increments = [ 5, 5, 10, 10, 20, 40]

export const DynamicGrid: ClosureComponent<Attrs> = vnode => {
    const state:State = {
        rows: [],
        count: increments[0],
        page: 0,
        redistribute: false
    }

    const more = () => {
        state.page = Math.min(increments.length-1, state.page+1)
        state.count += increments[state.page]
        window.history.pushState(
            {page: state.page},
            `page ${state.page}`,
            `${window.location.pathname.replace(/\&page=\d+$/, '')}&page=${state.page}`
        )
    }

    const distribute = async (params: Params) => {
        const rows: Row[] = await new Promise(resolve => DynamicLayout.Distribute(params, resolve))
        if (equal(rows, state.rows))
            return
        state.rows = rows
        m.redraw()
    }

    return {
        oninit: async vnode => {
            for (let page = parseInt((window.location.pathname.match(/&page=(\d+)$/) || ['', '1'])[1]); page > 1; page --)
                state.count += increments[++state.page]
            await distribute(vnode.attrs.params)
        },
        onupdate: async vnode => {
            await distribute(vnode.attrs.params)
        },
        oncreate: vnode => vnode.dom.classList.add('initialized'),
        view: vnode =>
            m('.dynamic-grid',
                state.rows.slice(0, state.count).map(row =>
                    m('.row',{style:{height: row[0].resize.height, width:'100%'}},
                        row.map((thumb, key) =>
                            m(LinkTile, {
                                key,
                                url: thumb.url,
                                title: vnode.attrs.showTitles ? thumb.id : undefined,
                                width: thumb.resize.width,
                                height: thumb.resize.height,
                                alt: thumb.alt,
                                filename: thumb.filename,
                                arrow: vnode.attrs.arrows,
                                scale: vnode.attrs.scale,
                                type: vnode.attrs.type
                            })
                        )
                    )
                ),
                state.rows.length > state.count &&
                    m('button.show-more', {onclick: more},
                        m(Picture, {srcset: [{src: 'images/collection/showmore.png', size: '1x'}], alt: 'show more',width:'50', height:'16'}),
                        'Show More'
                    )
            )
    }
}