'use strict';

/*
 * Generate a column layout for an array of mixed-aspect ratio images
 *
 *    photos       - array of objects with properties /width/ and /aspect/
 *    width        - column width to fit image layout to
 *    targetHeight - ideal height of photos to aim for
 *    gap          - gap in pixels to leave between photos
 *
 * Based on https://www.crispymtn.com/stories/the-algorithm-for-a-perfectly-balanced-photo-gallery
 */

import { DetectIE } from '../helpers';
if (DetectIE() !== false) {
    // Fallback to earlier implementation for IE; linear-partitioning hangs IE (all versions up to Edge) with mobile-width desktop window
    var LinearPartition = require('linear-partition');
} else {
    // This import is an improved version of npm module linear-partition, based on the algorthm in the crispymtn article
    var LinearPartition = require('linear-partitioning');
}

// This one function is factored into a single file as it is used by both the worker runtime and the main bundle for IE 9 fallback
function Distribute(photos, width, targetHeight, gap) {
    var summed_width = photos.reduce(((sum, p) => {
        if (!p.aspect && !p.width && !p.height) {
            console.log('aspect or width and height missing', p);
            return sum
        } else if (p.width && p.height) {
            p.aspect = p.width / p.height;    
        }
        return sum += p.aspect * targetHeight;
    }), 0);

    var rowCount = Math.round(summed_width / width);
    if (rowCount < 1) {
        photos.forEach(photo => {
            const W = parseInt(targetHeight * photo.aspect);
            photo.resize = {
                width: W,
                height: targetHeight
            };
        });
        return [ photos ];
    } else {
        var weights = photos.map(p => {
            return parseInt(p.aspect * 100);
        });
        var partition = LinearPartition(weights, rowCount);
        var index = 0;
        var rows = [];
        partition.forEach(row => {
            // Smaller screens can result in empty row
            if (row.length == 0) {
                return;
            }
            var summed_ratios;
            var row_buffer = [];
            row.forEach(r => {
                row_buffer.push(photos[index++]);
            });
            summed_ratios = row_buffer.reduce(((sum, p) => {
                return sum += p.aspect;
            }), 0);

            var total_gap = (row_buffer.length - 1) * gap;
            var local_gap = Math.ceil(total_gap / row_buffer.length);

            row_buffer.forEach(photo => {
                const W = parseInt(width / summed_ratios * photo.aspect) - local_gap;
                const H = parseInt(width / summed_ratios) - local_gap;
                photo.resize = {
                    width: W,
                    height: H,
                };
            });

            // The algorithm appears to generate rows that are too short by a handful of pixels.
            // What's the total width of the row? (discounting the last photo that has no margin-right)
            var row_width = row_buffer.reduce(((w, p) => {
                return w + p.resize.width + gap;
            }), -gap);
            // Distribute the shortfall evenly until none remains
            var shortfall = width - row_width;
            while (shortfall > 0) {
                row_buffer.forEach(p => {
                    if (shortfall-- > 0) {
                        p.resize.width++;
                    }
                });
            }

            rows.push(row_buffer);
        });
        return rows;
    }
};

module.exports = Distribute;
