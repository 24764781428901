import { Item } from "./types/item"
import Detect from './detect'
import { MEDIA } from "./constants"

// LEAVE COMMENTED FOR MOBILE SWIPE
// const fadeSameTime = (width:number, height:number, nextContainer:HTMLDivElement, opaque:HTMLDivElement, mobile = false) => {
//     if(!mobile){
//         window.itemPin!.style.opacity = '1'
//         //@ts-ignore
//         window.itemZoom!.parentNode!.style.opacity = '1'
//         window.thumbControls!.style.width = `${width}px`
//         window.thumbControls!.style.height = `${height}px`
//     }
//     nextContainer.classList.add('add-z-index')
//     nextContainer.classList.add('opaque')
//     opaque.classList.remove('opaque')
//     opaque.addEventListener('transitionend',()=>{
//         opaque.parentNode!.removeChild(opaque)
//         nextContainer.classList.remove('add-z-index')
//     },{once:true})
// }

// const handleFade = (width:number, height:number, currentW:number, next:HTMLDivElement, opaque:HTMLDivElement, mobile = false) => {
    // Leave commented for mobile swipe
    // if(mobile){
    //     fadeSameTime(width, height, next, opaque, true)
    //     return
    // }
    // if(width < currentW){
    //     fadeSameTime(width, height, next, opaque)
    // } else {
    // }
//     fadeByTurn(width, height, next, opaque)
// }

const dimensions = (item:Item) => {
    const height = window.innerWidth < 560
        ? Math.floor(window.innerHeight * 0.4)
        : Math.floor((item.width / item.height) * 315) > 560
            ? Math.floor(560 / (item.width / item.height))
            : 315
    const width = item.height > item.width
        ? Math.floor(item.width * (height / item.height))
        : Math.floor((item.width / item.height) * height)
    return {height, width}
}

export const crossFade = (item:Item) => {
    if (!window.productImage)
        return

    const {width, height} = dimensions(item)
    const img = new Image()
    img.onload = () => {
        const pin = window.itemPin!.parentElement!
        const zoom = window.itemZoom!.parentElement!
        const image = window.productImage!

        const nextPin = <HTMLAnchorElement>pin.cloneNode(true)
        const nextZoom = <HTMLAnchorElement>zoom.cloneNode(true)
        const nextImage = <HTMLDivElement>image.cloneNode()
        nextImage.prepend(img)

        window.thumbControls!.removeChild(pin)
        window.thumbControls!.removeChild(zoom)
        image.classList.remove('top-image')

        const appendedPin = window.thumbControls!.appendChild(nextPin)
        const appendedZoom = window.thumbControls!.appendChild(nextZoom)
        const appendedImage = window.mainImageAnchor!.appendChild(nextImage)

        appendedImage.addEventListener('animationend', () => {
            image!.parentNode!.removeChild(image)
        }, {once: true})

        window.itemPin = <HTMLAnchorElement>appendedPin.children[0]
        window.itemZoom = <HTMLAnchorElement>appendedZoom.children[0]
        window.productImage = appendedImage

        if(!Detect.mobile()){
            window.thumbControls!.style.width = `${width}px`
            window.thumbControls!.style.height = `${height}px`
        }
    }
    img.srcset = `${MEDIA}/r/${width}x${height}/${item.filename} 1x, ${MEDIA}/r/${Math.floor(width * 1.8)}x${Math.floor(height * 1.8)}/${item.filename} 1.8x`
    img.src = `${MEDIA}/r/${width}x${height}/${item.filename}`
    img.alt = `${item.alt}`
}