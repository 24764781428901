const KEY = 'recentlyViewed'
const MAX = 20

const removeDuplicates = (recent) => {
    // We reverse so that we can take the last instance of each item
    recent.reverse()
    const filtered = recent.filter(a => a == recent.find(b => a.id == b.id))
    return filtered.reverse()
}
const clean = recent => removeDuplicates(recent).slice(-MAX)
const get = () => clean(JSON.parse(localStorage.getItem(KEY) || '[]'))
const set = items => localStorage.setItem(KEY, JSON.stringify(items))

export const recent = () => get()
export const onView = item => {
    const viewed = get()
    viewed.push(item)
    set(clean(viewed))
}