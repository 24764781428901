import m, {Component} from 'mithril'
import {scale} from '../../scale'
import {thumbset} from '../../thumb'

type SliderItemAttrs = {
    url?: string,
    filename: string,
    alt?: string,
    onclick?: () => void
    width?: number
    height?: number
    aspect?: number
    name?: string,
    type?: 'main' | 'finishes' | 'details'
}

export const LinkItem:Component<SliderItemAttrs> = {
    view: vnode => {
        const {width, height} = scale(vnode.attrs)
        return m('a', {
            href: !vnode.attrs.onclick && vnode.attrs.url,
            onclick: vnode.attrs.onclick || vnode.attrs.type && (() =>
                window.openedLightbox = vnode.attrs.type
            )},
            m('img', {
                ...thumbset(vnode.attrs.filename, width, height),
                onload: m.redraw,
                alt: vnode.attrs.alt || vnode.attrs.name || 'recently-viewed-item'
            }),
            vnode.attrs.name &&
                m('.item-name',vnode.attrs.name)
        )
    }
}