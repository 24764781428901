for (const node of document.querySelectorAll('.open-modal')) {
    const id = `#${node.getAttribute('modalid')}`
    node.addEventListener('click', () => {location.href = id})
    const close = document.querySelector(`${id} .sp-modal-close`)
    close!.addEventListener('click', () => {
        const y = window.scrollY
        location.href = '#'
        window.scrollTo(0, y)
    })
}
