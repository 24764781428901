export const MOBILE_PORTRAIT_MAX = 850
export const MOBILE_LANDSCAPE_MAX = 750

export default {
    mobile: () => window.innerWidth <= MOBILE_PORTRAIT_MAX,
    layout: () => {
        const w = window.innerWidth
        const h = window.innerHeight
        if (w <= MOBILE_PORTRAIT_MAX && h > w)
            return 'portrait'
        if (w <= MOBILE_LANDSCAPE_MAX && h < w)
            return 'landscape'
        return 'desktop'
    }
}