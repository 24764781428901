'use strict';

import { DetectIE } from '../helpers';
var SparkMD5 = require('spark-md5');
const work = require('webworkify')

// - Spawn dynamic layout algorithm layout-worker.js in a background thread (or main thread if Web Workers are not supported)
// - Cache results for speed when flipping phone orientation

var _cache = {};

export function CalculateHash(params, namespace, usePriority) {
    const spark = new SparkMD5();

    if (namespace) {
        spark.append(namespace);
    }
    
    const v = [ `${params.width} ${params.targetHeight} ${params.gap}` ];
    params.thumbs.forEach(t => v.push(`${t.width} ${usePriority ? t.priority : ''}`))

    for (const p of v)
        spark.append(p)
    
    const hash = spark.end();

    return hash;
}

export function Distribute(params, callback, namespace) {
    const hash = CalculateHash(params, namespace, true);

    if (_cache[hash]) {
        callback(_cache[hash]);
        return;
    }

    if (DetectIE() === 10) {
        // IE10 worker hangs (calls worker.onerror with no further info)
        _DistributeSync(params, callback);
        return;
    }

    try {
        var worker = work(require('./layout-worker.js'));
    } catch (e) {
        // IE9 doesn't support Web Workers - run calculation directly
        _DistributeSync(params, callback);
        return;
    }

    worker.onerror = function(error) {
        console.log('worker error');
    }

    worker.onmessage = function(event) {
        _cache[hash] = event.data;
        callback(event.data);
    }

    worker.postMessage(params);
}

function _DistributeSync(params, callback) {
    var rows = require('./distribute')(params.thumbs, params.width, params.targetHeight, params.gap);
    callback(rows);
}
