import Detect from './detect'

// Check out item-tabs.jsx and thumbs.js for insight into the
// old app's method of scaling images

export const scale = (item: {aspect?: number, width?: number, height?: number}) => {
    const {aspect:a, width:w, height:h} = item
    const aspect = a || ((w && h) ? w! / h! : undefined)
    const height = Math.round(Detect.mobile()
        ? Math.max(Math.min(0.23 * window.innerWidth, 138), 115)
        : 138
    )
    const width = aspect
        ? w! > h!
            ? Math.round(height * aspect)
            : Math.round(w! * (height / h!))
        : 205
    return { width, height }
}