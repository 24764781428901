import m, {Component} from 'mithril'
import classnames from 'classnames'
import {Picture} from './picture'

type Attrs = {
    open: boolean
    close: () => void
}

export const Modal:Component<Attrs> = {
    view: vnode =>
        m('div', {class: classnames('sp-modal', {open: vnode.attrs.open})},
            m('.shadow'),
            m('button.sp-modal-close', {onclick: vnode.attrs.close},
                m(Picture, {srcset:[{src:'images/global/close/close-grey2.png', size:'1x'}], alt: 'x', width: '15', height: '15'})
            ),
            m('.sp-modal-box',
                vnode.children
            )
        )
}