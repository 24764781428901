import m from 'mithril'
import Detect from './detect'

type Subscriber = {
    once?: boolean
    callback: () => void
    called?: boolean
}

type State = {
    active: boolean
    mobile: boolean
    always: boolean
    resizeSubscribers: Subscriber[]
    breakSubscribers: Subscriber[]
}

const state: State = {
    active: false,
    mobile: Detect.mobile(),
    always: false,
    resizeSubscribers: [],
    breakSubscribers: []
}

const run = (subscribers: Subscriber[]) => {
    let called = false
    for (const s of subscribers)
        if (!s.once || !s.called) {
            s.callback()
            called = s.called = true
        }
    return called
}

const resize = () => {
    let redraw = run(state.resizeSubscribers)
    if (Detect.mobile() !== state.mobile) {
        state.mobile = !state.mobile
        redraw = redraw || run(state.breakSubscribers)
    }
    if (redraw || state.always)
        m.redraw()
}

const activate = () => {
    if (state.active)
        return
    window.addEventListener('resize', resize)
    state.active = true
}

export const onBreak = (callback?: () => void, once?: boolean) => {
    callback && state.breakSubscribers.push({callback, once, called: false})
    activate()
}

export const onResize = (callback?: () => void, once?: boolean) => {
    callback
        ? state.resizeSubscribers.push({callback, once, called: false})
        : state.always = true
    activate()
}