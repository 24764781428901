// THIS FILE MUST NOT BE TYPESCRIPT SO THAT
// THE POSTCSS CONFIG FILE CAN ALSO IMPORT IT

const fs = require('fs')

const md5Index = fs.readFileSync(__dirname + '/../../server/public/md5/static/md5.index', 'utf8')
const md5Map = md5Index.split('\n').reduce(
    (prev, curr) => Object.assign(prev, { [curr.split(' ')[0]]: curr.split(' ')[1]}),
    {}
)

const hashpath = (path, name, webp=name.endsWith('.webp')) =>
    `${webp ? "webp/" : ""}${path}/${md5Map[(webp ? 'webp/' : '') + path + '/' + name]}.${name}`

const hashfile = (path, name, webp=name.endsWith('.webp')) =>
    `${window['staticPath']}/${hashpath(path, name, webp)}`

module.exports = {hashpath, hashfile}