export const slide = (
    trackWidth: number,
    slideWidth: number,
    prev: number,
    percent: number,
    dir: 'right'|'left',
    loop: 'rewind'|'carousel'|undefined = undefined
    ) => {
    const a = {right: -1, left: 1}[dir]
    const d = percent * trackWidth
    const r = trackWidth - slideWidth
    const pos = prev + a * d
    const limit = Math.min(0, Math.max(r, pos))
    switch(loop) {
        case 'carousel': return pos
        case 'rewind': return dir === 'right' && prev === r ? 0 : dir === 'left' && prev === 0 ? r : limit
        case undefined: return limit
    }
}

export const shift = (trackWidth: number, sliderWidth: number, prev: number, dir: 'left' | 'right') =>
    dir === 'left'
        ? prev - trackWidth >= 0 ? prev - sliderWidth : prev
        : prev + trackWidth < 0 ? prev + sliderWidth : prev