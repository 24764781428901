import m, {Component} from 'mithril'
import Detect from '../detect'
import {Breadcrumb} from '../types/item'
import {onBreak} from '../resize'

type State = {
    base?: Breadcrumb
    breadcrumbs: Breadcrumb[]
}

const state: State = {
    base: undefined,
    breadcrumbs: []
}

export const setBreadcrumbs = (breadcrumbs: Breadcrumb[]) =>
    state.breadcrumbs = 
        breadcrumbs[breadcrumbs.length-1].name.startsWith('Item Q')
            ? breadcrumbs.slice(1)
            : breadcrumbs

const Breadcrumbs: Component<{}> = {
    view: vnode =>
        [state.base!, ...state.breadcrumbs].map((crumb, i) =>
            i === state.breadcrumbs.length
                ? m('span', {class: `item-name crumb-${i}`}, crumb.name)
                : [
                    m('a', {href: crumb.url, class: `crumb-${i}`}, crumb.name),
                    m('span.separator', '/')
                ]
        )
}

if (window.breadcrumbsNode) {
    const node = window.breadcrumbsNode
    const breadcrumbs = JSON.parse(node.getAttribute('breadcrumbs') || '[]')
    state.base = breadcrumbs[0]
    state.breadcrumbs = breadcrumbs.slice(1)
    m.mount(node, {view: () => m(Breadcrumbs)})
}

if (window.collectionsNavNode) {
    const node = window.collectionsNavNode
    if (!node.classList.contains('quickship')) {
        const add = () => node.classList.add('open')
        const remove = () => node.classList.remove('open')
        const toggle = () => node.classList.toggle('open')
    
        const collectionsNav = () => {
            if (Detect.mobile()) {
                node.querySelector('.label')!.removeEventListener('mouseenter', add)
                node.querySelector('.nav')!.removeEventListener('mouseleave', remove)
                node.querySelector('.dynamic')!.addEventListener('click', toggle)
                node.querySelector('.shadow')!.addEventListener('click', remove)
                return
            }
            node.querySelector('.dynamic')!.removeEventListener('click', toggle)
            node.querySelector('.shadow')!.removeEventListener('click', remove)
            node.querySelector('.label')!.addEventListener('mouseenter', add)
            node.querySelector('.nav')!.addEventListener('mouseleave', remove)
        }
    
        onBreak(collectionsNav)
        collectionsNav()
    }
}