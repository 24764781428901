'use strict';

// DynamicLayout can take a long time on a slow device - or UIWebView, so we run the algorithm
// in a Web Worker - this file is the worker code.

var Distribute = require('./distribute');

module.exports = function(self) {
    self.onmessage = function(event) {
        var rows = Distribute(event.data.thumbs,
                              event.data.width,
                              event.data.targetHeight,
                              event.data.gap);
        self.postMessage(rows);
    }
}
