import m, { Component } from "mithril";
import { Picture } from "./picture";
import { scale } from "../scale";
import { thumbset } from "../thumb";

type LinkTileAttrs = {
  url: string;
  title?: string | number;
  arrow?: boolean;
  width: number;
  height: number;
  alt: string;
  filename: string;
  scale?: boolean;
  type?: "main" | "finishes" | "details";
};

const LinkTile: Component<LinkTileAttrs> = {
  view: (vnode) => {
    const title =
      vnode.attrs.title && typeof vnode.attrs.title === "string"
        ? vnode.attrs.title.replace(/^Q(?=\d)/i, "")
        : vnode.attrs.title;
    const { width, height } = vnode.attrs.scale
      ? scale(vnode.attrs)
      : vnode.attrs;
    return m(
      "a.linktile .linktile-collection",
      {
        href: vnode.attrs.url,
        onclick: (e) => {
          if (vnode.attrs.url.includes("#"))
            window.openedLightbox = vnode.attrs.type;
        },
      },
      m(
        ".linktile-container",
        m("img", {
          ...thumbset(vnode.attrs.filename, width, height),
          alt: vnode.attrs.alt,
        }),
        vnode.attrs.arrow &&
          m(
            ".linktile-title-collection",
            m(
              ".linktile-title-arrow",
              m(Picture, {
                srcset: [
                  { src: "images/global/linktile/rollover.png", size: "1x" },
                  { src: "images/global/linktile/rollover@2x.png", size: "2x" },
                ],
                alt: "tile arrow",
                width: "72",
                height: "22",
              })
            ),
            m(".linktile-title-text", title)
          )
      )
    );
  },
};

export default LinkTile;
