'use strict';

var isNode = require('detect-node');

function DetectFlex() {
    if (isNode) {
        return false;
    }

    var test = document.createElement('test');

    test.style.display = 'flex';

    return test.style.display === 'flex';
}

module.exports = DetectFlex;
